import(/* webpackMode: "eager", webpackExports: ["TimeZoneChangeDetector"] */ "/srv/rallly/apps/web/src/app/[locale]/timezone-change-detector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/srv/rallly/apps/web/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/srv/rallly/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/srv/rallly/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager" */ "/srv/rallly/apps/web/src/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/srv/rallly/packages/ui/src/toaster.tsx");
